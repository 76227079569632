import React from 'react';

const ProductDetail = ({ props }) => {
  const { title, description, imagePath, caption } = props;
  return (
    <div className="md:px-8 py-8 grid md:grid-cols-5 grid-cols-1">
      <div className="md:p-10 md:col-span-3 py-6 flex flex-col items-center justify-center">
        <h3 className="text-2xl font-semibold">{title}</h3>
        <div>
          <p className="py-4 text-gray-700" style={{ whiteSpace: 'pre-wrap' }}>{description}</p>
          {caption && <p className="text-gray-700 text-xs">{caption}</p>}
        </div>
      </div>
      <img className="md:p-10 md:col-span-2" src={imagePath} />
    </div>
  );
};

export default ProductDetail;
