import React from "react";
import Layout from "../components/layout";

import ServiceTermBody from '../../assets/appInfo/ServiceTerm';
import ProductDetail from '../components/product-detail';
import appConfig from '../../config';

const productDetails = [
  {
    title: "記録はシンプルに",
    description: "登ったジムや岩場、時間、ひとことメモを残せます。",
    imagePath: "/No1.png",
  },
  {
    title: "国内600超のジムやクライミングスポットをサジェスト",
    description: `アプリ内にスポット情報が登録されているため、記録作成も簡単です。`,
    caption: "※外岩のスポットは順次追加中です。",
    imagePath: "/No2.png",
  },
  {
    title: "グラフで記録を表示",
    description: "あなたがよく行くジムや月ごとのクライミング活動時間をグラフで表示します。",
    imagePath: "/No3.png",
  },
];

const Home = () => {
  return (
    <Layout topHeader title="Home">
      <div className="flex items-center justify-center md:p-12 py-12">
        <h3 className="text-2xl">{`${appConfig.appName}で、ボルダリング、リードの記録を簡単に残せます。`}</h3>
      </div>
      {
        productDetails.map(v => {
          return <ProductDetail props={{...v}} />
        })
      }
    </Layout>
  );
};

export default Home;
